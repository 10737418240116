<template>
    <div>
        <PopupDialog :dialogOpen="dialog"/>
        <v-col>
            <div v-if="orederedRecommendedOffers.length" class="display-1 grey--text text--darken-1 mb-3">{{ $t("appHome.recommended") }}</div>
            <v-row wrap v-if="orederedRecommendedOffers.length">
                <v-col cols="12" v-for="(event, index) in orederedRecommendedOffers" :key="index">
                    <EventCard 
                    :event="event"
                    :showActions="true"
                    />
                </v-col>  
            </v-row>
            <div class="display-1 grey--text text--darken-1 mb-3">{{ $t("appHome.next") }}</div>
            <v-row wrap v-if="orderByDate(linkedEvents).length">
                <v-col cols="12" v-for="(event, index) in orderByDate(linkedEvents)" :key="index">
                    <EventCard 
                    :event="event"
                    :showActions="true"
                    />
                </v-col>  
            </v-row>
            <div v-else>
                <div class="grey--text mt-5">{{ $t("appHome.none") }}</div>
                <router-link tag="span" :to="{name: 'WebAppEventHome'}"><v-btn class="text-none mt-3" color="primary">{{ $t("appHome.search") }}</v-btn></router-link>
            </div>
            <div v-if="orderByDate(requestedEvents).length" class="display-1 grey--text text--darken-1 mb-3 mt-5">{{ $t("appHome.awaits") }}</div>
            <v-row wrap>
                <v-col cols="12" v-for="(event, index) in orderByDate(requestedEvents)" :key="index">
                    <EventCard 
                    :event="event"
                    :showActions="true"
                    />
                </v-col>  
            </v-row>
        </v-col>
    </div>
</template>

<script>
import EventCard from '@/components/events/EventCard'
import PopupDialog from '@/components/partials/PopupDialog'

import axios from 'axios'
    export default {
        name: "WebAppHome",
        components: {EventCard, PopupDialog},
        data() {
            return {
                dialog: false,
                linkedEvents: [],
                requestedEvents: [],
                recommendedOffers: [],
                recommendedIds: []
            }
        },
        methods: {
            getlinkedEvents() {
                let newlinkedEventsIds = []
                let newrequestedEventsIds = []
                axios.get('/auth/get_linked_offers')
                .then(response => {
                    var links = response.data.links
                    for (let i=0;i<links.length;i++) {
                        var link = links[i]
                        if (link.isActive) {
                            newlinkedEventsIds.push(link.ID_offer)
                        }
                    }
                    var requests = response.data.requests
                    for (let i=0;i<requests.length;i++) {
                        newrequestedEventsIds.push(requests[i].ID_offer)
                    }
                    this.linkedEvents = this.getEventContent(newlinkedEventsIds)
                    this.requestedEvents =this.getEventContent(newrequestedEventsIds)
                    this.getRecommendedOffers([...newlinkedEventsIds, ...newrequestedEventsIds])
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            getEventContent(ids) {
                let newEvents = []
                axios.post('/get_offers_info', {ID_offers: ids})
                .then(response => {
                    response.data.info.forEach(element => {
                        if (element.isActive) {
                            let newEvent = {
                                ...element,
                                tags: (element.tags || []).map(tag => {
                                  if(!this.$store.state.tags[tag]){
                                    const tags = this.$i18n.getLocaleMessage('sk-SK').tags;
                                    const key = Object.keys(tags).find(t => tags[t].name === tag);  // english tag name

                                    return key;
                                  } else {
                                    return tag;
                                  }
                                }),
                                isFull: element.linkedUsers >= element.demand,
                            }
                            newEvents.push(newEvent)
                        }
                    });
                })
                .catch(() => {
                    this.dialog = true
                })
                return newEvents
            },
            getRecommendedOffers(linkedIds) {
                axios.get('/auth/get_recommended_offers')
                .then(response => {
                    const ids = this.topThreeUnlinkedScoresIds(response.data.info.scores, linkedIds)
                    this.recommendedIds = ids
                    this.recommendedOffers = this.getEventContent(ids)
                })
                .catch(error => {
                    if (error.response && (error.response.data == "Access Denied" || error.response.data == "Invalid Token")) {
                        this.$router.push({ name: 'logout' })
                    } else {
                        this.dialog = true
                    }
                })
            },
            topThreeUnlinkedScoresIds(scores, linkedIds) {
                let top1 = -Infinity
                let top2 = -Infinity
                let top3 = -Infinity
                let top1id = null
                let top2id = null
                let top3id = null
                Object.entries(scores).forEach(([id, score]) => {
                    if (!linkedIds.includes(parseInt(id))) {
                        if (score > top1) {
                            top3 = top2
                            top3id = top2id
                            top2 = top1
                            top2id = top1id
                            top1 = score
                            top1id = id
                        } else if (score > top2) {
                            top3 = top2
                            top3id = top2id
                            top2 = score
                            top2id = id
                        } else if (score > top3) {
                            top3 = score
                            top3id = id
                        }
                    }
                })
                return [top1id, top2id, top3id]
            },
            orderByDate: function (events) {
                let orderedEvents = [...events.filter((event) => {
                    if (event.type == 0) {
                        return new Date(`${event.endDate}T${event.endTime}:00`) >= Date.now()
                    } else if (event.type == 1) {
                        return new Date(`${event.endDate}T24:00:00`) >= Date.now()
                    } else if (event.type == 2) {
                        return new Date(`${event.nextEventDate}T24:00:00`) >= Date.now() // should always be true
                    } else if (event.type == 3) {
                        return new Date(`${event.nextEventDate}T${event.endTime}:00`) >= Date.now() // should always be true
                    } else {
                        return true
                    }
                })]
                orderedEvents.sort((a, b) => {
                    if (a.type == 4) {
                        return 1
                    } else if (b.type == 4) {
                        return -1
                    } else {
                        return new Date(`${a.nextEventDate}T${a.nextEventTime || "00:00"}:00`).getTime() - new Date(`${b.nextEventDate}T${b.nextEventTime || "00:00"}:00`).getTime()
                    }
                })
                return orderedEvents
            }
        },
        computed: {
            orederedRecommendedOffers: function () {
                let newEvents = [...this.recommendedOffers]
                if (newEvents[0] && newEvents[1]) {
                    const ids = this.recommendedIds
                    if (newEvents[0].ID_offer != ids[0]) {
                        const a = newEvents[1]
                        newEvents[1] = newEvents[0] 
                        newEvents[0] = a
                    }
                    if (newEvents[0].ID_offer != ids[0]) {
                        const b = newEvents[2]
                        newEvents[2] = newEvents[0] 
                        newEvents[0] = b
                    }
                    if (newEvents[1].ID_offer != ids[1]) {
                        const c = newEvents[1]
                        newEvents[1] = newEvents[2] 
                        newEvents[2] = c
                    }
                }
                return newEvents
            }
        },
        created() {
            this.getlinkedEvents()
        }
    }
</script>

<style lang="scss" scoped>

</style>